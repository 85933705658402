import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form } from "@unform/web";
import history from "../../../../services/history";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Row,
  Col,
  FormText,
  Spinner,
  CustomInput,
} from "reactstrap";

import { Map, TileLayer, Marker } from "react-leaflet";
import Input from "../../../components/Input";
import AvatarInputGd from "../../../components/AvatarInputGd";
import apiGd from "../../../../services/apiGd";
import NotificationAlert from "react-notification-alert";
import Select from "../../../components/Form/SelectInput";
import { FaEye } from "react-icons/fa";
import RowDivider from "../../../components/RowDivider";
import CheckboxInput from "../../../components/Checkbox";
import { useRestaurants } from "context/RestaurantsContext";
import ReactInputMask from "react-input-mask";

export default function NewRestaurant() {
  const token = localStorage.getItem("@QRExpress:tokenGd");

  const notificationAlert = useRef(null);
  const [inicialLocation, setInicialLocation] = useState([0, 0]);
  const [selectedPosition, setSelectedPosition] = useState([0, 0]);
  const [clone, setClone] = useState(false);
  const [restaurantsToClone, setRestaurantsToClone] = useState({});
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");
  const [loading, setLoading] = useState(false);
  const [hasClube, setHasClube] = useState(false);
  const [hasTables, setHasTables] = useState(false);
  const [hasCommand, setHasCommand] = useState(false);
  const [hasBalcony, setHasBalcony] = useState(false);
  const [name, setName] = useState('');
  const [instagram, setInstagram] = useState('');
  const [plan, setPlan] = useState()
  const [asaasId, setAsaasId] = useState(null);

  const [onlyQrcode, setOnlyQrcode] = useState(false);
  const [useStock, setUseStock] = useState(false);
  const [isDeliveryAllowed, setIsDeliveryAllowed] = useState(false);
  const [onlyDelivery, setOnlyDelivery] = useState(false);
  const [isWithdrawalAllowed, setIsWithdrawalAllowed] = useState(false);
  const [isDeliveryAddressesAllowed, setIsDeliveryAddressesAllowed] = useState(false);
  const [isPixAllowed, setIsPixAllowed] = useState(false);
  const [isCreditCardAllowed, setIsCreditCardAllowed] = useState(false);
  const [onlyQrcodeDisabled, setOnlyQrcodeDisabled] = useState(false);
  const [nfceCheck, setNfceCheck] = useState(false);
  const [useWpp, setUseWpp] = useState(false)
  const [useSmartPos, setUseSmartPos] = useState(false)
  const [users, setUsers] = useState([]);
  const [responsibleUser, setResponsibleUser] = useState(null);

  const getUsers = useCallback(async () => {
    try{
      const res = await apiGd.get("/restaurants/users", {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      
      //removendo o usuario Admin/Padrao do nosso dash
      const users = res.data.filter(data => data.id !== 1).map(data => {
        return {
          label: data.name,
          value: data.id
        }
      });

      setUsers(users);
    }catch(err){
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  function changeOnlyDelivery() {
    setOnlyDelivery(!onlyDelivery);
    setIsDeliveryAllowed(true);
    if(!onlyDelivery === true){
      setOnlyQrcode(true);
      setOnlyQrcodeDisabled(true);
      setTableLimitType({value: 'limited-tables', label: 'Limitar mesas'});
      setTableLimitValue(0);
    }else{
      setOnlyQrcode(false);
      setOnlyQrcodeDisabled(false);
    }
  }

  function changeOnlyQrcode() {
    setOnlyQrcode(!onlyQrcode);
  }

  function changeDeliveryAllowed() {
    setIsDeliveryAllowed(!isDeliveryAllowed);
  }

  function changeWithdrawalAllowed() {
    setIsWithdrawalAllowed(!isWithdrawalAllowed);
  }

  function changeDeliveryAddressesAllowed() {
    setIsDeliveryAddressesAllowed(!isDeliveryAddressesAllowed);
  }

  const { getRestaurants } = useRestaurants();

  function changeClubeCheck() {
    setHasClube(!hasClube);
  }

  function changeUseWpp(){
    setUseWpp(!useWpp)
  }

  function changeUseSmartPos(){
    setUseSmartPos(!useSmartPos)
  }

  function changePixAllowed() {
    setIsPixAllowed(!isPixAllowed);
  }

  function changeCreditCardAllowed() {
    setIsCreditCardAllowed(!isCreditCardAllowed);
  }

  function changeTablesCheck() {
    setHasTables(!hasTables);
  }

  function changeCommandsCheck() {
    setHasCommand(!hasCommand);
  }

  function changeBalconyCheck() {
    setHasBalcony(!hasBalcony);
  }

  function changeNfceCheck() {
    setNfceCheck(!nfceCheck);
  }

  async function getRestaurantsToClone() {
    try {
      const response = await apiGd.get("admin/restaurants", {
        headers: { Authorization: `Bearer ${tokenGd}` },
      });

      const restaurants = response.data.map((restaurant) => ({
        value: restaurant.id,
        label: `${restaurant.fantasy_name} - ${restaurant.name}`,
      }));

      setRestaurantsToClone(restaurants);
      setClone(true);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }

  function changeCloneToClone() {
    if (!clone) {
      getRestaurantsToClone();
    } else {
      setClone(false);
    }
  }

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  async function handleSubmitNewQRR(data) {
    if(!data.asaas_id){
      if(!window.confirm('Tem certeza que deseja criar sem o Asaas associado?')){
        setLoading(false);
        return false;
      }
    }

    if(hasTables && (!data.startTable || !data.lastTable)){
      notify("tr", "danger", "Preencher o valor da primeira e última mesa.");
      setLoading(false);
      return false;
    }

    if(hasCommand && (!data.startCommand || !data.lastCommand)){
      notify("tr", "danger", "Preencher o valor da primeira e última comanda.");
      setLoading(false);
      return false;
    }

    try {
      setLoading(true);
      let isThisWithdrawalAllowed = false;

      if(data.delivery_info.is_delivery_allowed || data.only_delivery){
        isThisWithdrawalAllowed = true;
      }

      const profile = {
        name: data.name?.toLowerCase(),
        fantasy_name: data.fantasy_name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        owner_wpp: data.owner_wpp !== '' ? data.owner_wpp : null,
        avatar_id: data.avatar,
        latitude: data.latitude,
        longitude: data.longitude,
        is_location_limited: false,
        instagram: data.instagram,
        responsible_user_id: data.responsible_user_id,
        has_clube: hasClube,
        percent: data.percent || null,
        minimo: data.minimo || null,
        asaas_id: data.asaas_id || null,
        only_qrcode: onlyQrcode, 
        only_delivery: !hasBalcony && !hasTables && !hasCommand && isDeliveryAllowed, 
        use_wpp: data.use_wpp,
        use_smart_pos: useSmartPos,
        has_balcony: hasBalcony,
        delivery_info: {
          is_withdrawal_allowed: isThisWithdrawalAllowed,
          allow_delivery_addresses: data.delivery_info.allow_delivery_addresses,
        },
        has_pix: data.has_pix, 
        has_credit_card: data.has_credit_card, 
        paytime_seller_id: data.paytime_seller_id, 
        has_nfce: data.has_nfce, 
        nfce_token: data.nfce_token, 
        regime_tributario: data.regime_tributario, 
        cnpj: data.cnpj?.replaceAll('.', '').replaceAll('/', '').replaceAll('-',''),
        adress: {
          city: data.city || null,
          neighborhood: data.neighboorhood || null,
          state: data.state || null,
          street: data.street || null,
          zip_code: data.zip_code || null,
          number: data.number || -1
        }
      };

      if(hasTables && data.startTable && data.startTable !== '' && data.lastTable && data.lastTable !== ''){
        profile.has_tables = true;
        profile.startTable = data.startTable;
        profile.lastTable = data.lastTable;
        profile.table_type = data.prepaid ? 'prepaid' : 'table'
      }


      if(hasCommand && data.startCommand && data.startCommand !== '' && data.lastCommand && data.lastCommand !== ''){
        profile.has_command = true;
        profile.startCommand = data.startCommand;
        profile.lastCommand = data.lastCommand;

      }

      const tableOptions = [
        {value: 'only-balcony', label: 'Somente balcão'},
        {value: 'limited-tables-10', label: 'Limite de 10 mesas'},
        {value: 'limited-tables-30', label: 'Limite de 30 mesas'},
        {value: 'limited-tables', label: 'Limitar mesas'},
        {value: 'unlimited-tables', label: 'Mesas ilimitadas'},
      ];

      let table_limit_type = tableLimitType?.value;
      let table_limit = data.table_limit;

      if(tableLimitType === 'limited-tables-10'){
        table_limit_type = 'limited-tables';
        table_limit = 10;
      }else if(tableLimitType === 'limited-tables-30'){
        table_limit_type = 'limited-tables';
        table_limit = 30;
      }else if(tableLimitType === 'limited-tables'){
        if(!table_limit || table_limit === ''){
          notify("tr", "danger", "Limite o numero de mesas.");
          setLoading(false);
          return false;
        }
      }

      /* profile.table_limit_type = table_limit_type; */
      /* profile.table_limit = table_limit ? parseInt(table_limit) : null; */


      if(plan){
        profile.plan = plan
      }

      const response = await apiGd.post("restaurants", profile, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (clone) {
        try {
          const to_clone = {
            restaurant_id1: data.restaurant_to_clone,
            restaurant_id2: response.data.id,
          };

          await apiGd.post("admin/restaurants/clone-menu", to_clone, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } catch (err) {
          notify("tr", "danger", "Erro ao clonar estabelecimento.");
        }
      }
      // getRestaurants();
      setLoading(false);
      notify("tr", "success", "Estabelecimento criado com sucesso!");
    } catch (err) {
      setLoading(false);
      if (!err.response.ok) {
        if (err.response.data.errorType === "email_already_exists") {
          notify("tr", "danger", err.response.data?.message || "Email já cadastrado");
        } else {
          notify("tr", "danger", "Erro ao cadastrar estabelecimento.");
        }
      }
    }
  }

  function handleMapClick(event) {
    setSelectedPosition([event.latlng.lat, event.latlng.lng]);
    setLatitude(event.latlng.lat);
    setLongitude(event.latlng.lng);
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setInicialLocation([position.coords.latitude, position.coords.longitude]);
      setSelectedPosition([
        position.coords.latitude,
        position.coords.longitude,
      ]);
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  function searchLocation() {
    setInicialLocation([latitude, longitude]);
    setSelectedPosition([latitude, longitude]);
  }

  function handleShowPassword() {
    const inp = document.getElementById("password");
    inp.type = inp.type === "password" ? "text" : "password";
  }

  const [validCep, setValidCep] = useState(true);

  const formRef = useRef()

  async function handleSearchCep(e) {
    if (e.target.value.charAt(8) !== '_') {
      const cep = e.target.value.replace('-', '').replace('.', '');

      const response = await apiGd.get(`/public/delivery/cep/${cep}`);

      if (!response.data.erro) {
        const streetInput = formRef.current.getFieldRef('street');
        const neighboorhoodInput = formRef.current.getFieldRef('neighboorhood');
        const cityInput = formRef.current.getFieldRef('city');
        const stateInput = formRef.current.getFieldRef('state');
        streetInput.value = response.data.logradouro;
        neighboorhoodInput.value = response.data.bairro;
        cityInput.value = response.data.localidade;
        stateInput.value = response.data.uf;
        setValidCep(true);
      } else {
        const streetInput = formRef.current.getFieldRef('street');
        const neighboorhoodInput = formRef.current.getFieldRef('neighboorhood');
        const cityInput = formRef.current.getFieldRef('city');
        setValidCep(false);
        streetInput.value = '';
        neighboorhoodInput.value = '';
        cityInput.value = '';
      }
    }
  }

  const tableOptions = [
    {value: 'only-balcony', label: 'Somente balcão'},
    {value: 'limited-tables-10', label: 'Limite de 10 mesas'},
    {value: 'limited-tables-30', label: 'Limite de 30 mesas'},
    {value: 'limited-tables', label: 'Limitar mesas'},
    {value: 'unlimited-tables', label: 'Mesas ilimitadas'},
  ];

  const [tableLimitType, setTableLimitType] = useState({value: 'unlimited-tables', label: 'Mesas ilimitadas'});
  const [isTableLimitDisabled, setIsTableLimitDisabled] = useState(true);
  const [tableLimitValue, setTableLimitValue] = useState(null);

  const [asaasRestaurants, setAsaasRestaurants] = useState([]);

  async function getAsaas(){
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const responseAsaas = await apiGd.get(`admin/asaas-customers`, reqConfig);
      setAsaasRestaurants(responseAsaas.data?.response_array);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações Asaas.");
    }
  }

  useEffect(() => {
    getAsaas();
    getUsers();
  }, []);

  useEffect(() => {
    switch(tableLimitType.value){
      case 'unlimited-tables':
        setTableLimitValue('');
        break;
      case 'limited-tables-10':
        setTableLimitValue(10);
        break;
      case 'limited-tables-30':
        setTableLimitValue(30);
        break;
      case 'limited-tables':
        !onlyDelivery && setTableLimitValue('');
        break;
      case 'only-balcony':
        setTableLimitValue('');
        break;
    }
  }, [tableLimitType.value]);


  const regimeTributarioOptions = [
    { value: "1", label: "Simples Nacional" },
    { value: "3", label: "Regime Normal" }
  ];

  const typeOfPlansOptions = [
    {value: "BÁSICO", label: "BÁSICO"},
    {value: "INTERMEDIÁRIO", label: "INTERMEDIÁRIO"},
    {value: "INOVAÇÃO", label: "INOVAÇÃO"},
    {value: "PRO", label: "PRO"},
    {value: "APENAS CARDÁPIO", label: "APENAS CARDÁPIO"}
  ]


  function handleChangeTypeOfPlans(e){

    setPlan(e)

    if(e === "APENAS CARDÁPIO"){
      setOnlyQrcode(false)
      setOnlyDelivery(false)
      setIsDeliveryAllowed(false)
      setHasTables(false)
      setIsPixAllowed(false)
      setIsCreditCardAllowed(false)
      setNfceCheck(false)
      setHasClube(false)
      setHasBalcony(false)
      setUseWpp(false)
    }

    if(e === "BÁSICO"){
      setOnlyQrcode(false)
      setOnlyDelivery(true)
      setIsDeliveryAllowed(true)
      setHasTables(false)
      setIsPixAllowed(false)
      setIsCreditCardAllowed(false)
      setNfceCheck(false)
      setHasClube(false)
      setHasBalcony(false)
      setUseWpp(false)
    }

    if(e === "INTERMEDIÁRIO"){
      setOnlyQrcode(false)
      setOnlyDelivery(false)
      setIsDeliveryAllowed(true)
      setHasTables(true)
      setIsPixAllowed(false)
      setIsCreditCardAllowed(false)
      setNfceCheck(false)
      setHasClube(false)
      setHasBalcony(true)
      setUseWpp(false)
    }

    if(e === "INOVAÇÃO"){
      setOnlyQrcode(true)
      setOnlyDelivery(false)
      setIsDeliveryAllowed(true)
      setHasTables(true)
      setIsPixAllowed(false)
      setIsCreditCardAllowed(false)
      setNfceCheck(false)
      setHasClube(false)
      setHasBalcony(true)
      setUseWpp(false)
    }

    if(e === "PRO"){
      setOnlyQrcode(true)
      setOnlyDelivery(false)
      setIsDeliveryAllowed(true)
      setHasTables(true)
      setIsPixAllowed(false)
      setIsCreditCardAllowed(false)
      setNfceCheck(true)
      setHasClube(true)
      setHasBalcony(true)
      setUseWpp(true)
    }
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="3">
            <Button color="default" onClick={() => history.goBack()}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left" />
              </span>
              Voltar
            </Button>
          </Col>
        </Row>
        <h3>Cadastro de Estabelecimentos Garçom Digital</h3>
        <Form className="form-horizontal" onSubmit={handleSubmitNewQRR} ref={formRef}>
          <Row>
            <Col md="4" sm="4">
              <CardTitle tag="h4">Logo</CardTitle>
              <AvatarInputGd name="avatar" />
              <Card style={{ padding: 20 }}>
                <Row style={{ margin: "auto" }}>
                  <p>
                    <strong>Asaas</strong>
                  </p>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      {
                        asaasRestaurants.length > 0 ?
                        <Select
                          name="asaas_id"
                          options={asaasRestaurants}
                          // onChange={e => setAsaasId(e.value)}
                        /> : 
                        <p style={{textAlign: 'center'}}>Carregando...</p>
                      }
                    </FormGroup>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <CardBody>
                  {/* <Row>
                      <ButtonGroup size="md" style={{margin: "auto"}}>
                        <Button onClick={changeCloneToNew} innerRef={buttonNew}>Restaurante Novo</Button>
                        <Button onClick={changeCloneToClone}>Clone de Restaurante</Button>
                      </ButtonGroup>
                    </Row> */}
                  <Row>
                    <Label sm="2">Clone de cardápio</Label>
                    <Col style={{ marginTop: 15 }} sm="10">
                      <CustomInput
                        onClick={changeCloneToClone}
                        type="switch"
                        id="switchClone"
                        name="switchToClone"
                      />
                    </Col>
                  </Row>

                  {clone && (
                    <Row style={{ paddingTop: 15 }}>
                      <Label sm="2">Restaurante de qual clonar:</Label>
                      <Col sm="10">
                        <Select
                          name="restaurant_to_clone"
                          options={restaurantsToClone}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Label sm="2">Nome de Exibição</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="fantasy_name" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Nome de Usuário</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input 
                          type="text" 
                          name="name" 
                          value={name}
                          onChange={e => setName(e?.target?.value?.replace(/[^A-Z0-9_]/gi, ""))}
                        />
                        <FormText color="default" tag="span">
                          Esse nome é um identificador único do estabelecimento,
                          deve ter letras minúsculas e sem espaço. Ex:
                          'mcdonalds' / 'bobs' / 'burgerking'
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Telefone</Label>
                    <Col sm="4">
                      <FormGroup>
                        <Input type="phone" name="phone" />
                      </FormGroup>
                    </Col>
                    <Label sm="2">WhatsApp Dono</Label>
                      <Col sm="4">
                        <FormGroup>
                          <ReactInputMask
                            mask="(99) 99999-9999"
                          >
                            {()=>(
                              <Input type="text" name="owner_wpp" />
                            )}
                          </ReactInputMask>
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                    <Label sm="2">E-mail</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="email" name="email" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Senha</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          type="password"
                          name="password"
                          autoComplete="off"
                          id="password"
                          style={{ paddingLeft: "30px" }}
                        />
                        <FaEye
                          title="Mostrar senha"
                          onClick={handleShowPassword}
                          style={{
                            cursor: "pointer",
                            marginTop: "-80px",
                            marginLeft: 10,
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Instagram</Label>
                    <Col sm="10">
                      <FormGroup>
                          <Input
                            type="text"
                            name="instagram"
                            autoComplete="off"
                            value={instagram}
                            onChange={e => setInstagram(e?.target?.value?.replace('@', ''))}
                          />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Usuário Responsável</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Select
                          name="responsible_user_id"
                          options={users}
                          value={users.find(user => user.value === responsibleUser) || []}
                          onChange={e => setResponsibleUser(e.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <RowDivider title="Localização" style={{marginTop: 15}}/>

                  <Row>
                    <Col md="4">
                    <label>CEP</label>
                      <ReactInputMask
                        mask="99999-999"
                        onChange={(e) => handleSearchCep(e)}
                      >
                        {()=>(

                            <Input name="zip_code"  />

                        )}
                        </ReactInputMask>
                        {!validCep && <span style={{color: 'red'}}>CEP Inválido</span>}
                        </Col>
                        <Col md="4">
                          <label>Logradouro</label>
                          <Input name="street" type="text" />
                        </Col>

                        <Col md="4">
                          <label>Número</label>
                          <Input name="number" type="text"  />
                        </Col>
                    </Row>
                    <Row>
                    <Col md="4">
                          <label>Bairro</label>
                          <Input name="neighboorhood" type="text"  />
                        </Col>

                        <Col md="4">
                          <label>Cidade</label>
                          <Input name="city" type="text"  />
                        </Col>

                        <Col md="4">
                          <label>Estado</label>
                          <Input name="state" type="text"  />
                        </Col>
                    </Row>
                    <RowDivider title="Predefinição de plano" />
                    <Row>
                      <Col sm="12">
                        <Select
                          name="type_of_plan"
                          options={typeOfPlansOptions}
                          placeholder="Selecione o plano"
                          onChange={e=>handleChangeTypeOfPlans(e.value)}
                        />
                      </Col>
                    </Row>
                    <RowDivider title="Pedidos via QR Code" />
                    <Row>
                    <Col md="12" style={{ marginLeft: 20 }}>
                      <CheckboxInput
                        label="Habilitar pedidos via QR Code"
                        checked={!!onlyQrcode}
                        name="only_qrcode"
                        onChange={changeOnlyQrcode}
                        disabled={onlyQrcodeDisabled}
                      />
                    </Col>
                  </Row>

                  <RowDivider title="Delivery" />
                  {/* <Row>
                    <Col md="12" style={{ marginLeft: 20 }}>
                      <CheckboxInput
                        label="Apenas Delivery"
                        checked={!!onlyDelivery}
                        name="only_delivery"
                        onChange={changeOnlyDelivery}
                      />
                    </Col>
                  </Row> */}

                  <Row>
                    <Col md="12" style={{ marginLeft: 20 }}>
                      <CheckboxInput
                        label="Habilitar Delivery e Retirada"
                        checked={!!isDeliveryAllowed}
                        name="delivery_info.is_delivery_allowed"
                        onChange={changeDeliveryAllowed}
                   
                      />
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col md="12" style={{ marginLeft: 20 }}>
                      <CheckboxInput
                        label="Habilitar Retirada"
                        checked={!!isWithdrawalAllowed}
                        name="delivery_info.is_withdrawal_allowed"
                        onChange={changeWithdrawalAllowed}
                      />
                    </Col>
                  </Row> */}

                  <Row>
                    <Col md="12" style={{ marginLeft: 20 }}>
                      <CheckboxInput
                        label="Habilitar o cadastro dos endereços de entrega"
                        checked={!!isDeliveryAddressesAllowed}
                        name="delivery_info.allow_delivery_addresses"
                        onChange={changeDeliveryAddressesAllowed}
                      />
                    </Col>
                  </Row>

                  <RowDivider title="PDV" style={{marginTop: 15}}/>

                  {/* <Row>
                    <Col md="6">
                      <FormGroup>
                        <Select
                          name="table_limit_type"
                          options={tableOptions}
                          label="Limitar mesas"
                          defaultValue={{value: 'unlimited-tables', label: 'Mesas ilimitadas'}}
                          value={tableLimitType}
                          onChange={e => {setTableLimitType({label: e.label, value: e.value}); e.value === 'limited-tables' ? setIsTableLimitDisabled(false) : setIsTableLimitDisabled(true)}}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <Input
                        name="table_limit"
                        label="Limitar mesas"
                        disabled={isTableLimitDisabled}
                        value={tableLimitValue}
                        type="number"
                        onChange={e => setTableLimitValue(e.target.value)}
                      />
                    </Col>
                  </Row> */}
                   <Row>
                    <Col sm="12" style={{ marginLeft: 20 }}>
              
                        <CheckboxInput
                          label="Habilitar Balcão"
                          name="has_balcony"
                          checked={hasBalcony}
                          onChange={changeBalconyCheck}
                        />
                     
                    </Col>
                  </Row>

                  {!onlyDelivery &&
                  <>
                  <Row>
                    <Col sm="12" style={{ marginLeft: 20 }}>
                      {hasTables ? (
                        <CheckboxInput
                          label="Criar Mesas"
                          checked={hasTables}
                          name="has_tables"
                          onChange={changeTablesCheck}
                        />
                      ) : (
                        <CheckboxInput
                          label="Criar Mesas"
                          name="has_tables"
                          onChange={changeTablesCheck}
                        />
                      )}
                    </Col>
                  </Row>

                  {hasTables && (
                    <>
                    <Row >
                      <Col sm="12" style={{ marginLeft: 20 , marginTop: 10}}>
                      <CheckboxInput
                          label="Mesas pré-pagas"
                          name="prepaid"
                          
                        />
                      </Col>
                      
                    </Row>
                    <Row style={{marginTop: 10}}>
                      <Col className="pr-1" md="6">
                        <label>Primeira Mesa</label>
                        <Input name="startTable" type="number" defaultValue={1}/>
                      </Col>
                      <Col md="6">
                        <label>Última Mesa</label>
                        <Input name="lastTable" type="number" />
                      </Col>
                    </Row>
                    </>
                  )}
                  

                  <Row >
                        <Col sm="12" style={{ marginLeft: 20 , marginTop: 10}}>
                        <CheckboxInput
                            label="Criar Comandas"
                            name="comands"
                            onChange={changeCommandsCheck}
                            checked={hasCommand}
                          />
                        </Col>
                        
                      </Row>

                  {hasCommand && (
                    <>
                     
                      <Row style={{marginTop: 10}}>
                        <Col className="pr-1" md="6">
                          <label>Primeira Comanda</label>
                          <Input name="startCommand" type="number" defaultValue={1}/>
                        </Col>
                        <Col md="6">
                          <label>Última Comanda</label>
                          <Input name="lastCommand" type="number" />
                        </Col>
                      </Row>
                    </>
                  )}

</>}
                  <RowDivider title="Pagamento Online" />
                  <Row>
                    <Col md="12" style={{ marginLeft: 20 }}>
                      <CheckboxInput
                        label="Habilitar Pix Online"
                        checked={!!isPixAllowed}
                        name="has_pix"
                        onChange={changePixAllowed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" style={{ marginLeft: 20 }}>
                      <CheckboxInput
                        label="Habilitar Cartão de Crédito Online"
                        checked={!!isCreditCardAllowed}
                        name="has_credit_card"
                        onChange={changeCreditCardAllowed}
                      />
                    </Col>
                  </Row>
                  {(isPixAllowed || isCreditCardAllowed) && (
                    <Row>
                      <Col md="12">
                        <Input
                          name="paytime_seller_id"
                          placeholder="Token"
                          type="text"
                        />
                      </Col>
                    </Row>
                  )}

                    <RowDivider title="Nota Fiscal" />

                    <Row>
                      <Col sm="12" style={{ marginLeft: 20 }}>
                        {nfceCheck ? (
                          <CheckboxInput
                            label="Habilitar Emissão de Nota Fiscal"
                            checked={nfceCheck}
                            name="has_nfce"
                            onChange={changeNfceCheck}
                          />
                        ) : (
                          <CheckboxInput
                            label="Habilitar Emissão de Nota Fiscal"
                            name="has_nfce"
                            onChange={changeNfceCheck}
                          />
                        )}
                      </Col>
                    </Row>

                    {nfceCheck && (
                      <Row>
                        <Col className="pr-1" md="4">
                          <label>Token de Produção</label>
                          <Input
                            name="nfce_token"
                            placeholder="Token"
                            type="text"
                          />
                        </Col>

                        <Col md="4">
                          <label>CNPJ</label>
                          <ReactInputMask mask="99.999.999/9999-99">
                            {()=>(
                              <Input
                                name="cnpj"
                                type="text"
                                placeholder="xx.xxx.xxx/xxxx-xx"
                              />
                            )}
                          </ReactInputMask>
                        </Col>

                        <Col md="4">
                          <FormGroup style={{marginTop: 5}}>
                            <Select
                              name="regime_tributario"
                              options={regimeTributarioOptions}
                              label="Regime Tributário"
                              defaultValue={regimeTributarioOptions[0]}
                              // onChange={e => setResponsibleUser(e.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                  <RowDivider title="Clube" style={{marginTop: 10}}/>

                  <Row>
                    <Col sm="12" style={{ marginLeft: 20 }}>
                      {hasClube ? (
                        <CheckboxInput
                          label="Criar Clube de Benefícios"
                          checked={hasClube}
                          name="has_clube"
                          onChange={changeClubeCheck}
                        />
                      ) : (
                        <CheckboxInput
                          label="Criar Clube de Benefícios"
                          name="has_clube"
                          onChange={changeClubeCheck}
                        />
                      )}
                    </Col>
                  </Row>

                  {hasClube && (
                    <Row>
                      <Col className="pr-1" md="6">
                        <label>Porcentagem</label>
                        <Input name="percent" placeholder="%" type="text" />
                      </Col>
                      <Col md="6">
                        <label>Resgate Mínimo</label>
                        <Input name="minimo" type="text" placeholder="R$" />
                      </Col>
                      {/* <Col md="4">
                        <label>CEP</label>
                        <Input name="cep" type="text" placeholder="" />
                      </Col> */}
                    </Row>
                  )}

                  {/* <Row>
                    
                    <Col md="5">
                      <FormGroup>
                      <label>Latitude</label>
                        <Input
                          type="float"
                          name="latitude"
                          defaultValue={latitude}
                          onChange={(e) => setLatitude(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                 
                    <Col md="5">
                      <FormGroup>
                      <label>Longitude</label>
                        <Input
                          type="float"
                          defaultValue={longitude}
                          name="longitude"
                          onChange={(e) => setLongitude(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" className="mt-3">
                   
                      <Button onClick={searchLocation} color="info">
                        <i className="nc-icon nc-zoom-split" />
                      </Button>
                    </Col>
                  </Row>

                  <Label>Geolocalização:</Label>
                  <Map
                    center={inicialLocation}
                    zoom={14}
                    style={{ height: 250 }}
                    onClick={handleMapClick}
                  >
                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={selectedPosition}></Marker>
                  </Map> */}

                  <RowDivider title="Automação do Whatsapp" />
                  <Row>
                    <Col md="12" style={{ marginLeft: 20 }}>
                      <CheckboxInput
                        label="Habilitar automação de whatsapp"
                        name="use_wpp"
                        onChange={changeUseWpp}
                        checked={useWpp}
                      
                      />
                    </Col>
                  </Row>
                  <RowDivider title="Smart POS" />
                  <Row >
                    <Col sm="12" style={{ marginLeft: 20 , marginTop: 10}}>
                    <CheckboxInput
                        label="Utilizar smart POS"
                        name="use_smart_pos"
                        onChange={changeUseSmartPos}
                        checked={useSmartPos}
                      />
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: 15 }}>
                    <Col md="3" className="ml-auto">
                      <Button color="success" type="submit">
                        {loading ? (
                          <span>
                            <Spinner size="sm" color="white" />
                          </span>
                        ) : (
                          <span>Cadastrar</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
