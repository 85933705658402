import styled from "styled-components";
import { Link } from "react-router-dom";

export const ButtonTab = styled(Link)`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 10px 30px 10px 30px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 40px;

  ${({ thisButton, activeButton }) =>
    activeButton === thisButton &&
    `
    border-bottom: red 3px solid;
  `}

  :hover {
    border-bottom: red 2px solid;
    color: inherit; /* blue colors for links t */
    text-decoration: inherit; /* no underline */
  }

  :focus {
    color: inherit; /* blue colors for links t */
    text-decoration: inherit; /* no underline */
  }
`;
