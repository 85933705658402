import React, { useState, useEffect } from "react";
import ResearchTR from "views/components/ResearchTR";
import {
  Button,
  Spinner,
  CardBody,
  Table,
  Card,
} from "reactstrap";
import formatDateYear from "services/formatDateYear";
import apiGd from "services/apiGd";
import format from "date-fns/format";
import { subMonths } from "date-fns";
import { FaDownload, FaChevronUp } from "react-icons/fa";
import { generateCsatResearchSheet } from "services/generateCsatManagerResearchSheet";
import FileSaver from "file-saver";
import axios from "axios";
import {
  FilterTable,
} from "ui-kit-takeat";

  const research = {
    title: "CSAT Gestor",
    question: "Pesquisa CSAT para Dashboard do gestor",
    method: "custom",
    createdAt: "2021-08-01",
    id: 136,
    type: "one-per-user-per-month",
  };

export const Success = () => {
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");

  const [loading, setLoading] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [months, setMonths] = useState([]);
  const [sorting, setSorting] = useState({
    type: "desc",
    id: 0,
  });
  const [header, setHeader] = useState([
    "Restaurante", "MRR", "Variação CSAT (mês anterior)"]);
  const [sortedRestaurants, setSortedRestaurants] = useState([]);

  useEffect(() => {
    setRestaurants(restaurants.sort(sortingFunction));
  }, [restaurants, sorting]);
  
  useEffect(() => {
    setHeader([...header, ...months.map((month) => format(month, "MMM"))]);
  }, [months]);

  const sortingFunction = (a, b) => {
    if (sorting.id === 0) {
      return a.fantasy_name.localeCompare(b.fantasy_name) * (sorting.type === "asc" ? 1 : -1);
    }
    if (sorting.id === 1) {
      if (!a.mrr) return 1;
      if (!b.mrr) return -1;
      return (Number(a.mrr) - Number(b.mrr)) * (sorting.type === "asc" ? 1 : -1);
    }
    if (sorting.id === 2) {
      const aValueOne = a.answers[0];
      const aValueTwo = a.answers[1];
      const bValueOne = b.answers[0];
      const bValueTwo = b.answers[1];
      if (aValueOne === 0 || aValueTwo === 0) return 1;
      if (bValueOne === 0 || bValueTwo === 0) return -1;
      return ((aValueOne / aValueTwo) - (bValueOne / bValueTwo)) * (sorting.type === "asc" ? 1 : -1);
    }
    if (sorting.id === 3) {
      if (a.answers[0] === 0) return 1;
      if (b.answers[0] === 0) return -1;
      return (Number(a.answers[0]) - Number(b.answers[0])) * (sorting.type === "asc" ? 1 : -1);
    }
    if (sorting.id === 4) {
      if (a.answers[1] === 0) return 1;
      if (b.answers[1] === 0) return -1;
      return (Number(a.answers[1]) - Number(b.answers[1])) * (sorting.type === "asc" ? 1 : -1);
    }
    if (sorting.id === 5) {
      if (a.answers[2] === 0) return 1;
      if (b.answers[2] === 0) return -1;
      return (Number(a.answers[2]) - Number(b.answers[2])) * (sorting.type === "asc" ? 1 : -1);
    }
    if (sorting.id === 6) {
      if (a.answers[3] === 0) return 1;
      if (b.answers[3] === 0) return -1;
      return (Number(a.answers[3]) - Number(b.answers[3])) * (sorting.type === "asc" ? 1 : -1);
    }
    if (sorting.id === 7) {
      if (a.answers[4] === 0) return 1;
      if (b.answers[4] === 0) return -1;
      return (Number(a.answers[4]) - Number(b.answers[4])) * (sorting.type === "asc" ? 1 : -1);
    }
    if (sorting.id === 8) {
      if (a.answers[5] === 0) return 1;
      if (b.answers[5] === 0) return -1;
      return (Number(a.answers[5]) - Number(b.answers[5])) * (sorting.type === "asc" ? 1 : -1);
    }
  };

  const getAnswers = async () => {
    const m = [];
    const today = new Date();
    for (let i = 0; i < 6; i++) {
      m.push(subMonths(today, i));
    }
    setMonths(m);

    setLoading(true);
    await apiGd.get("/admin/research-csat-manager", {
      params: {
        research_id: research.id,
      },
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    }).then((response) => {
      setRestaurants(response.data);
    }).catch((error) => {
      console.log("error", error);
    }).finally(() => setLoading(false));
  };

  useEffect(() => {
    getAnswers();
  }, []);

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateCsatResearchSheet(
        restaurants,
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: "application/excel" });

      FileSaver.saveAs(
        blob,
        `${research.title}.xlsx`
      );
    } catch (err) {
      console.log("Error download sheet > ", err);
    }
  };

  const [loadingMrr, setLoadingMrr] = useState(false);

  const handleUpdateMrr = async () => {
    setLoadingMrr(true);
    await apiGd.get("/admin/update-restaurants-mrr",{
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    }).then((response) => {
      console.log("response", response.data);
    }).catch((error) => {
      console.log("error", error);
    }).finally(() => setLoadingMrr(false));
  };

  const [restaurantsOpened, setRestaurantsOpened] = useState([]);

  const handleOpenRestaurant = async (restaurant) => {
    if (restaurantsOpened.includes(restaurant.id)) {
      setRestaurantsOpened(restaurantsOpened.filter((id) => id !== restaurant.id));
    } else {
      setRestaurantsOpened([...restaurantsOpened, restaurant.id]);
    }
  }

  return (
    <>
      <div className="content" style={{ display: 'flex', flexDirection: 'column' }}>
        <h3>Successo</h3>
        <Button
          name="download_button"
          style={{float: "left", marginTop: 0, display: 'flex', alignItems: 'center', gap: 8, width: 'fit-content'}}
          color="default"
          onClick={() => handleDownloadSheet()}
        >
          Imprimir
          <FaDownload size={15}/>
        </Button>
        <Button
          name="update_mrr_button"
          style={{float: "left", marginTop: 0}}
          color="default"
          onClick={() => handleUpdateMrr()}
        >
          {loadingMrr ? (
            <Spinner size="sm" /> 
          ) : (
              <>Update MRR</>
            )}
        </Button>
      <Card style={{ overflowY: "scroll", maxHeight: "600px", marginTop: 15 }}>
        <CardBody>
         { loading ? 
            <div style={{color: 'red', padding: 15, textAlign: 'center'}}>
              <Spinner /> 
            </div> :
        <FilterTable
          clickableHeaders={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
          header={header}
          defaultFilter={sorting}
          onChangeFilter={(e) => setSorting(e)}
          thStyles={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 2,
          }}
          tableStyles={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          {loading ? (
            <div>
              <Spinner />
            </div>
          ) : (
              <>
              <tbody>
                {restaurants.map((restaurant) => {
                  return (
                    <>
                      <tr
                        key={restaurant.id}
                        onClick={() => handleOpenRestaurant(restaurant)}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>
                          <FaChevronUp size={12} style={{ marginRight: 4, transform: restaurantsOpened.includes(restaurant.id) ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
                          {restaurant.fantasy_name}
                          <br/>
                          {restaurant.deleted_at && <span style={{ color: "#FF2C3A" }}>DELETADO</span>}
                        </td>
                        <td>{restaurant.mrr ? `R$${restaurant.mrr}` : '-'}</td>
                        {restaurant.answers.length > 1 && restaurant.answers[0] > 0 && restaurant.answers[1] > 0 ? (
                          <td>{(restaurant.answers[0] / restaurant.answers[1]).toFixed(2)}</td>
                        ) : (
                          <td>-</td>
                        )}
                        {restaurant.answers.length > 1 && restaurant.answers.map((answer, index) => (
                          <td style={{ textAlign: 'center' }} key={index}>{answer ? answer.toFixed(2) : '-'}</td>
                        )
                        )}
                      </tr>
                      {restaurantsOpened.includes(restaurant.id) &&
                        restaurant.users.length > 0 &&
                        restaurant.users.map((user) => (
                          <tr key={user.id}>
                            <td style={{ paddingLeft: 8, fontSize: 12 }}>{user.name}<br/>{user.email && <span style={{ color: "#FF2C3A" }}>{user.email}</span>}</td>
                            <td style={{ fontSize: 12 }}>{restaurant.mrr ? `R$${restaurant.mrr}` : '-'}</td>
                            {user.answers.length > 1 && user.answers[0].value && user.answers[1].value ? (
                              <td style={{ fontSize: 12 }}>{(user.answers[0].value / user.answers[1].value).toFixed(2)}</td>
                            ) : (
                              <td style={{ fontSize: 12 }}>-</td>
                            )}
                            {user.answers.length > 1 && user.answers.map((answer, index) => (
                              <td style={{ textAlign: 'center', fontSize: 12 }} key={index}>{answer.value ? answer.value : '-'}</td>
                            )
                            )}
                          </tr>
                        )
                      )}
                    </>
                  )}
                )}
              </tbody>
            </>
          )}
        </FilterTable>
        }
        </CardBody>
      </Card>
      </div>
    </>
  );
};
